@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

* {
  font-family: "Poppins", sans-serif !important;
  font-style: normal !important;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #eeeeee;
  color: white;
}

.backgroundDIV {
  background-color: black;
  background-image: linear-gradient(to bottom, transparent 0%, #000000 95%),
    url("./assets/images/background.webp");

  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% auto;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: auto;
  z-index: -1;
}

@media (max-width: 1200px) {
  .backgroundDIV {
    background-position: top;
  }
}

.loginDiv {
  width: 300px;
  padding: 10px;
  margin: 0 auto;
  margin-top: 50px;
  border: 2px solid rgba(255, 255, 255, 0.5);
  border-radius: 10px;
  backdrop-filter: blur(15px);
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
  text-align: center;
}

.main {
  margin: 0 auto;
  max-width: 1300px;
}

.parent {
  display: flex;
}

.parent > div {
  width: 50%;
}

.puzzles {
  list-style: none;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 30px 10px;
  padding: 10px;
}

.puzzleDiv {
  border-style: none;
  border-radius: 0px;
  border-color: black;
  border-width: 2px;
  padding: 10px;
  background-color: white;
  max-width: 750px;
  text-align: center;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.row {
  display: flex;
  height: 30px;
}

.column {
  flex: 50%;
  margin-right: 1px;
}

.languageheader {
  color: rgb(105, 105, 105);
  position: fixed;
  top: 0;
  width: 100%;
  text-align: right;
  font-size: small;
}

.flag {
  display: inline-block;
  margin-right: 10px;
}

.flag:hover {
  cursor: pointer;
}

.loginFooter {
  color: rgb(105, 105, 105);
  position: fixed;
  bottom: 0;
  width: 100%;
  text-align: right;
  font-size: small;
  right: 10px;
}

.footer {
  color: #000000;
  text-align: center;
}

.footer * {
  margin: 15px;
}

.puzzleName {
  font-size: 0.9em;
  margin-top: 5px;
  margin-left: 5px;
  text-align: left;
  font-weight: 500;
  text-transform: uppercase;
  color: rgb(134, 134, 134);
}

.puzzleName.column {
  width: 100px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.puzzleIcons {
  text-align: right;
  margin-right: 10px;
  font-size: 12px;
  margin-top: 5px;
  width: 20px;
}

.puzzleimage {
  max-width: 100%;
}

.modalImage .puzzleimage {
  width: 90%;
  padding: 10px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  margin-bottom: 10px;
}

.puzzleimage:hover {
  cursor: pointer;
}

.filter {
  display: flex;
  min-height: 45px;
  line-height: 45px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  margin-bottom: 10px;
}

.grid-container {
  width: 33%;
  text-align: center;
}

.box1 {
  text-align: center;
}

.box1 * {
  margin-left: 15px;
  margin-right: 15px;
}

.box1 button {
  background-color: rgb(255, 177, 177);
  border-style: solid;
  border-width: 1px;
}

.box2 {
  text-align: center;
}

.box3 {
  text-align: right;
}

.filterButton {
  margin-left: 10px;
  width: 100px;
  height: 25px;
  border-style: solid;
  border-color: rgb(100, 100, 100);
  border-width: 1px;
  cursor: pointer;
}

.filterButtonActive {
  background-color: #4b977b;
  color: white;
}

.filterButton.expand {
  width: 40px;
  border-style: none;
}

.closeDiv .filterButton {
  height: 34px;
}

.modal {
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

.modal-content {
  background-color: #ffffff;
  float: right;
  padding: 20px;
  height: 100%;
  border: 1px solid #888;
  max-width: 70%; /* Could be more or less, depending on screen size */
  color: rgb(0, 0, 0);
}

.modalImage {
  text-align: center;
}

.modalImage h2 {
  margin-bottom: 20px;
}

.closeDiv {
  text-align: right;
  line-height: 32px;
  margin-right: 10px;
}

.bubble {
  text-align: left;
}

#log2 {
  border: 1px solid #ccc;
  border-radius: 10px;
  width: 200px;
  padding: 10px;
  margin: 15px auto 0;
  position: absolute;
  background: #bdd4bd;
  /* height: 50px; */
  text-align: center;
  font-size: 12px;
  line-height: 30px;
}

#log2:after {
  content: "";
  display: block;
  position: absolute;
  border-style: solid;
  border-color: #ccc;
  border-width: 1px 0 0 1px;
  width: 15px;
  height: 15px;
  top: -9px;
  right: 180px;
  background: inherit;

  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

/* ---- Checkbox from getcssscan ---- */
.checkbox-wrapper-22 {
  margin-left: 5px;
  text-align: left;
}

.checkbox-wrapper-22 .switch {
  display: inline-block;
  height: 34px;
  position: relative;
  width: 60px;
}

.checkbox-wrapper-22 .switch input {
  display: none;
}

.checkbox-wrapper-22 .slider {
  background-color: #ccc;
  bottom: 0;
  cursor: pointer;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: 0.4s;
}

.checkbox-wrapper-22 .slider:before {
  background-color: #fff;
  bottom: 4px;
  content: "";
  height: 26px;
  left: 4px;
  position: absolute;
  transition: 0.4s;
  width: 26px;
}

.checkbox-wrapper-22 input:checked + .slider {
  background-color: #66bb6a;
}

.checkbox-wrapper-22 input:checked + .slider:before {
  transform: translateX(26px);
}

.checkbox-wrapper-22 .slider.round {
  border-radius: 34px;
}

.checkbox-wrapper-22 .slider.round:before {
  border-radius: 50%;
}
